// Here you can add other styles
// MODAL

.modal-fullscreen>.modal-dialog {
  margin: 0;
  width: 100%;
  height: auto;
  padding: 0;
  max-width: none;

  .modal-content {
    height: auto;
    width: auto;
  }
}

.modal-xl {
  @media (min-width: 992px) {
    max-width: 100% !important;
    margin: 0 !important;
  }
}

.vz-modal-custom-xl {
  .modal-xl {
    @include desktop {
      max-width: 1920px;
      margin: 0;
    }
  }
}

.react-tel-input .form-control {
  width: 100% !important;
}

.form-control.select-no-option {
  color: #b0b2b7;
}

.c-hidden {
  display: none !important;
}

.center-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.btn-height {
  height: 38px;
}

.btn-interaction {
  opacity: 0.7;

  &:hover {
    opacity: 0.85;
  }

  &:active {
    opacity: 1;
  }
}

.card {
  .card-body {
    .table {
      margin-bottom: 0;

      td {
        &:first-child {
          width: 35%;
        }
      }
    }
  }
}

.table tbody> :first-child {
  td {
    border-top: 0;
  }
}

.cursor-pointer {
  cursor: pointer !important;
}

.input-searchbox {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .react-search-field {
    width: 100%;
    border: none !important;

    button {
      display: none;
    }
  }

  .react-search-field-input {
    border: 1px solid #d8dbe0 !important;
    border-radius: 0.25rem;
  }
}