// Variable overrides
$primary: #f73d3c;
$dark: #2f363a;
$active-color: #0075ff;

// BREAKPOINTS
$bp-mobile: 576px;
$bp-tablet: 992px;
$bp-desktop: 1200px;

// RESPONSIVE MIXINS
@mixin mobile {
  @media (max-width: #{$bp-mobile - 1px}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$bp-tablet}) and (max-width: #{$bp-tablet - 1px}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$bp-desktop}) {
    @content;
  }
}
