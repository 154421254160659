.statistic-table {
  padding: 0 5px;
  height: 338px;
}

.card-wrap {
  text-align: center;
  height: 100%;
  .card {
    margin-bottom: 10px;
    height: 100%;
  }
}

.statistic-dateRange {
  margin-bottom: 20px;
  .row {
    justify-content: flex-end;
  }
}

.card-body {
  display: flex;
  justify-content: center;
  flex-direction: column;
  h1 {
    margin: 25px 0;
    font-size: 70px;
  }
}

.card-detail {
  padding: 0;
  width: 100%;
  p {
    margin: 5px;
  }
}

.face-backjob-chart {
  width: 100%;
}

.face-wrap {
  margin-top: 20px;
}

.border-card {
  border: 1px solid #d8dbe0;
  overflow: hidden;
  border-radius: 0.25rem;
}

.chart-wrap {
  background: white;
  height: 300px;
  max-width: 100%;
}

.pie-chart {
  background: transparent;
  height: 420px;
  min-width: 420px;
}

.data-range {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.rs-anim-fade.rs-anim-in {
  left: calc(100vw - 636px) !important;
}

.teble-chart {
  width: 330px;
  height: 150px;
  justify-content: center;
}

.bar_line_chart {
  width: 100%;
  background-color: white;
  margin-bottom: 25px;
  .apexcharts-canvas {
    width: 100%;
  }
}

.small_pei_chart {
  width: 100% !important;
  margin: 0 !important;
}

.total-card {
  width: 100%;
  .card {
    width: 100%;

    .card-body {
      height: 165px;
    }
  }
}

.text-end {
  text-align: end;
}
