body {
  margin: 0;
  overflow-x: hidden;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  color: #3c4b64;
  background-color: #ebedef;
}
a {
  text-decoration: none;
  background-color: transparent;
  color: #f73d3c;
}
a:hover,
a:focus {
  color: #f73d3c;
  text-decoration: none;
}
a:active {
  color: #f73d3c;
  text-decoration: none;
}

.password-messages {
  p {
    margin-bottom: 1rem;
  }
}

.text-muted {
  margin-bottom: 1rem;
}
